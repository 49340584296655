import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import SelectorUpload from 'components/assets/AssetGalleryDialog/components/content/sidebar/components/selectors/upload/components/selector-upload';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import File from 'components/data/Files';
import AssetHelper from 'helpers/asset.helper';
import { OnMutationOptions } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogProps';
import SelectorHelper from '../../../helpers/selector-helper';
import useUploadSelector from './hooks/use-upload-selector';

interface SelectorUploadWrapperState {
    maxFileSize: number;
    multiple: boolean;
    acceptedTypes: string;
    imageFormat: string;
    sourceData: SourceData[] | boolean;
    extension: string;
}

interface Props {
    onMutation: (data: unknown, type?: string, options?: OnMutationOptions) => void;
    onChangeAcceptedList?: (acceptedList: File[]) => void;
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
}

/**
 * The responsibility of this wrapper component is to that renders the SelectorUpload component and inject necessary props to it which comes from the AssetGalleryDialog Component store.
 * @param onMutation Event handler for mutating the Asset Gallery Dialog data/value.
 * @param onChangeAcceptedList Event handler for changing the accepted list.
 * @param onSelect Event handler for selecting an asset.
 */
const SelectorUploadWrapper: React.FC<Props> = ({ onSelect, onMutation, onChangeAcceptedList }) => {
    const { acceptedTypes, maxFileSize, sourceData, extension, multiple } = useComponentStore<SelectorUploadWrapperState>('AssetGalleryDialog', {
        fields: {
            imageFormat: 'data.assetData.imageFormat',
            acceptedTypes: 'config.acceptedTypes',
            maxFileSize: 'config.maxFileSize',
            sourceData: 'data.sourceData',
            extension: 'value.extension',
            multiple: 'config.multiple'
        }
    });

    const { handleSelectorUpload, handleSelectorRemove, handleOnChangeAcceptedList } = useUploadSelector(onSelect, onMutation, onChangeAcceptedList);

    const fileType = AssetHelper.getFileType(extension);

    return (
        <SelectorUpload
            acceptedTypes={acceptedTypes}
            maxFileSize={maxFileSize}
            list={SelectorHelper.getDataSourceListByAcceptedFileType(sourceData, fileType)}
            onRemove={(item) => handleSelectorRemove(item, onMutation)}
            onAdd={handleSelectorUpload}
            onChangeAcceptedList={handleOnChangeAcceptedList}
            onSelect={onSelect}
            multiple={multiple}
        />
    );
};

export default SelectorUploadWrapper;
