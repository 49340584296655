import React from 'react';
import ContentWithSidebarWrapper from 'components/ui-components/Dialog/components/content-with-sidebar-wrapper';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStore from 'components/data/ComponentStore';
import File from 'components/data/Files';
import AssetGalleryDialogProps, { OnMutationOptions } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogProps';
import AssetGalleryDialogSidebarContent from './components/content';
import AssetGalleryDialogSelectorType from '../../../interfaces/AssetGalleryDialogSelectorType';
import assetGalleryDialogSidebarSelectors from './data/sidebar-selectors';
import AssetGalleryDialogState from '../../../interfaces/AssetGalleryDialogState';
import SelectorHelper from './helpers/selector-helper';

import './styles/main.scss';

/**
 * If there is no selector selected, the first selector from the selectors will be selected by default.
 */
const DEFAULT_SELECTOR_INDEX = 0;
interface AssetGalleryDialogSideBarState {
    selectors: AssetGalleryDialogSelectorType[];
}

interface Props {
    selectorsProps?: AssetGalleryDialogProps['selectorsProps'];
    onClose: () => void; // Closes the dialog.
    onMutation: (data: unknown, dataType?: string, options?: OnMutationOptions) => void; // Saves data to the store.
    onChangeAcceptedList?: (acceptedList: File[]) => void; // Callback function the return the acceptedList. You can for example use this to check how many assets are going to go through the upload process
}

/**
 * This component is responsible for rendering the sidebar of the AssetGalleryDialog.
 */
const AssetGalleryDialogSideBar: React.FC<Props> = ({ onChangeAcceptedList, onClose, onMutation, selectorsProps }) => {
    const { selectors: selectorTypes } = useComponentStore<AssetGalleryDialogSideBarState>('AssetGalleryDialog', {
        fields: { selectors: 'components.sidebar.selectors' }
    });

    /**
     * Gets the default selector.
     */
    const getDefaultSelector = () => {
        const assetGalleryDialog: AssetGalleryDialogState | undefined = ComponentStore.get('AssetGalleryDialog');
        const selectedSelector = assetGalleryDialog?.components.sidebar.selectedSelector;

        return selectedSelector ? selectedSelector : selectorTypes[DEFAULT_SELECTOR_INDEX] ?? '';
    };

    /**
     * Handles the click event of the selector.
     * Sets the current selected selector item.
     * @param selectedSelector - The selected selector type.
     */
    const handleSelectorClick = (selectedSelector: AssetGalleryDialogSelectorType) => {
        ComponentStore.setModel('AssetGalleryDialog', 'components.sidebar.selectedSelector', selectedSelector);
    };

    return (
        <ContentWithSidebarWrapper
            classes={{ root: 'asset-gallery-dialog-sidebar-wrapper', content: 'asset-gallery-dialog-sidebar-wrapper__content' }}
            sidebarItems={SelectorHelper.filterSidebarSelectorsByTypes(selectorTypes, assetGalleryDialogSidebarSelectors)}
            defaultActiveSidebarItem={getDefaultSelector()}
            onChangeActiveSidebarItem={(type) => handleSelectorClick(type as AssetGalleryDialogSelectorType)}>
            <AssetGalleryDialogSidebarContent
                onChangeAcceptedList={onChangeAcceptedList}
                onMutation={onMutation}
                onClose={onClose}
                selectorsProps={selectorsProps}
            />
        </ContentWithSidebarWrapper>
    );
};

export default AssetGalleryDialogSideBar;
